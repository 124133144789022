// LIMS Variables
$lims-logo: url(./../images/lims-logo-w.png);
$lims-bg: url(./../images/lims-bg.jpg);
$lims-navdarker: #04778e;
$lims-navdark: #007c95;
$lims-bgdarker: #00687E;
$lims-bgdark: #00829c;
$lims-navgradient: linear-gradient(to top, $lims-navdarker, $lims-navdark);
$lims-gradient: linear-gradient(to top, $lims-bgdarker, $lims-bgdark);

// ELN Variables
$eln-logo: url(./../images/eln-logo-w.png);
$eln-bg: url(./../images/eln.jpg);
$eln-bgdarker: #3b5d8c;
$eln-bgdark: #456591;
$eln-gradient: linear-gradient(to top, $eln-bgdarker, $eln-bgdark);
$eln-navgradient: linear-gradient(to top, $eln-bgdarker, $eln-bgdark);

// LIMS-ELN Variables
$lims-eln-logo: url(./../images/lims-eln-logo-w.png);
$lims-eln-bg: url(./../images/lims-eln-w.jpg);
$lims-eln-bgdarker: #243B5C;
$lims-eln-bgdark: #263f62;
$lims-eln-gradient: linear-gradient(to top, $lims-eln-bgdarker, $lims-eln-bgdark);
$lims-eln-navgradient: linear-gradient(to top, $lims-eln-bgdarker, $lims-eln-bgdark);

// Main Screen Variables
$lab-logo: url(./../images/lab-logo.png);
$lab-bg: url(./../images/lab.jpg);

$login-button: #ee7d31;
$label-color:#5a5959;
$label-weight:600;

// Compulsary Functions

@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}